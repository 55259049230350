import React, {
	createContext,
	FC,
	ReactNode,
	useEffect,
	useState,
	useMemo,
	useCallback,
	useContext
  } from 'react';
  import PropTypes from 'prop-types';
  import AuthContext from './authContext';
  
  import { useFetch } from '../hooks/useFetch';
	import { endpoints,fetchData } from '../helpers/api';

  // Types
  export interface IUserInfoContextProps {
	userInfo: any;
	setUserInfo?(userInfo: any): void;
	isLoading: boolean;
	fetchUserInfo?(): Promise<void>;
  }
  
  interface IUserInfoContextProviderProps {
	children: ReactNode;
  }
  
  const UserInfoContext = createContext<IUserInfoContextProps>(
	{} as IUserInfoContextProps
  ); 
  
  export const UserInfoContextProvider: FC<IUserInfoContextProviderProps> = ({ children }) => {
	const [userInfo, setUserInfo] = useState<any>(null);
	const [isLoading, setIsLoading] = useState(true);
	const { user } = useContext(AuthContext);

	const fetchUserInfo = useCallback(async () => {
		if (!user) return;
		try {
		//   const url = endpoints.getUserInfo(); 
		//   const response:any  = await fetchData(url);
		//   setUserInfo(response.data.user);
		  setIsLoading(false);
		} catch (error) {
		  console.error("Failed to fetch user info: ", error);
		  setIsLoading(false);
		}
	  }, [user]); 
	
	  useEffect(() => {
		if (user) { 
		  fetchUserInfo();
		} else {
			setIsLoading(false);
		}
	  }, [fetchUserInfo, user]);


	  const value = useMemo(
		() => ({
		  userInfo,
		  setUserInfo,
		  isLoading,
		  fetchUserInfo,
		}),
		[userInfo, isLoading, fetchUserInfo]
	  );
	
	  return (
		<UserInfoContext.Provider value={value}>{children}</UserInfoContext.Provider>
	  );
	};
  
  
  export default UserInfoContext;
  