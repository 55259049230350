import React, {
	createContext,
	FC,
	ReactNode,
	useEffect,
	useState,
	useMemo,
  } from 'react';
  import PropTypes from 'prop-types';
  import { User } from '../common/data/models/user';

  
  // Types
  export interface IAuthContextProps {
	user: User | null;
	setUser?(user: User | null): void;
	isLoading: boolean;
  }
  
  interface IAuthContextProviderProps {
	children: ReactNode;
  }
  
  // Context
  const AuthContext = createContext<IAuthContextProps>(
	{} as IAuthContextProps
  );
  
  // Helper function to get the initial user value from localStorage
  const getInitialUser = (): User | null => {
	const loggedInUser = localStorage.getItem('logged_in_user');
	return loggedInUser ? JSON.parse(loggedInUser) : null;
  };
  
  export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
	children,
  }) => {
	const [user, setUser] = useState<User | null>(getInitialUser());
	const [isLoading, setIsLoading] = useState(true);
  
	// Set loading to false once the component is mounted
	useEffect(() => {
	  setIsLoading(false);
	}, []);
  
	// Update localStorage based on the user's state
	useEffect(() => {
	  if (user) {
		localStorage.setItem('logged_in_user', JSON.stringify(user));
	  } else {
		localStorage.removeItem('logged_in_user');
	  }
	}, [user]);
  
	const value = useMemo(
	  () => ({
		user,
		setUser,
		isLoading,
	  }),
	  [user, isLoading]
	);
  
	return (
	  <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
  };
  
  AuthContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
  };
  
  export default AuthContext;  