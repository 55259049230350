import React, { FC } from 'react';
import PropTypes from 'prop-types';

interface ILogoProps {
	width?: number;
	height?: number;
  isLight: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, isLight }) => {

  var darkOrWhiteColor = "#010502";
  if(isLight){
    darkOrWhiteColor = "#FFFFFF";
  }


	return (
	  <svg
		xmlns="http://www.w3.org/2000/svg"
		width={height !== 854 && !!height ? height * (2155 / 854) : width}
		height={width !== 2155 && !!width ? width * (854 / 2155) : height}
		viewBox="0 0 3200 922"
    fill={darkOrWhiteColor}
		style={{
			shapeRendering: "geometricPrecision",
			textRendering: "geometricPrecision",
			fillRule: "evenodd",
			clipRule: "evenodd",
		  }}
		>

        <text x="100" y="500" font-family="Arial" font-size="444" fill="black">Bino Solutions</text>

    </svg>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
