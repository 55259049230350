import React, { ReactNode, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation, { NavigationLine } from '../../../layout/Navigation/Navigation';
import User from '../../../layout/User/User';
import { dashboardPagesMenu, mainMenu, profileMenu } from '../../../menu';
import ThemeContext from '../../../contexts/themeContext';
import Icon from '../../../components/icon/Icon';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import Popovers from '../../../components/bootstrap/Popovers';
import { Trans } from 'react-i18next';


const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const navigate = useNavigate();

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation();

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noreferrer');
	};

	

	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				<Navigation menu={mainMenu} id='aside-main-menu' />
			</AsideBody>
			<AsideFoot>
				<nav aria-label='aside-bottom-menu'>
					<div className='navigation'>
									
					</div>
				</nav>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;